import React, {useState} from "react";
import {Navbar, Nav} from "react-bootstrap";
import {IoSchoolOutline, IoNewspaperOutline} from "react-icons/io5";
import {GoTools} from "react-icons/go";
import {FaProjectDiagram, FaPhoneAlt} from "react-icons/fa";
import "./Navbar.css";


function NavBar() {

  const [expand, setExpand] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  const isExpanded = () => setExpand(expand ? false : "expanded");

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
      <>
      <Navbar expanded={expand} fixed="top" expand="lg" className={navColour ? "sticky" : "navbar"}>
            
            <Navbar.Brand className="brand" href="#about">
              Ronald Koh
            </Navbar.Brand>
     
            <Navbar.Toggle className="navbar-dark" aria-controls="basic-navbar-nav" onClick={isExpanded}>
            <span></span>
            <span></span>
            <span></span>
            </Navbar.Toggle>
           
            <Navbar.Collapse id="basic-navbar-nav" className="navbar-content justify-content-end">
              <Nav>

                  <Nav.Item>
                    <Nav.Link className="nav-link" href="#education" onClick={() => setExpand(false)}>
                      <IoSchoolOutline size={25}/>{" "}Education
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="nav-link" href="#skills" onClick={() => setExpand(false)}>
                      <GoTools />{" "}Skills
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="nav-link" href="#projects" onClick={() => setExpand(false)}>
                      <FaProjectDiagram />{" "}Projects
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="nav-link" href="#contact" onClick={() => setExpand(false)}>
                      <FaPhoneAlt />{" "}Contact
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="nav-link" href="https://www.figma.com/proto/khzaQbLyYepHgge0698VDq/Ronald's-Resume-Figma-Link?" target="_blank" onClick={() => setExpand(false)}>
                      <IoNewspaperOutline />{" "}Resume
                    </Nav.Link>
                  </Nav.Item>

              </Nav>
            </Navbar.Collapse>

      </Navbar>
      </> 
  );
}

export default NavBar;