import React, {useEffect} from "react";
import Aos from "aos";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Skillset from "./components/Skillset/Skillset";
import Projects from "./components/Projects/Projects";
import CertContact from "./components/Cert-Contact/Cert-Contact";
import Footer from "./components/Footer/Footer";
import MessageSubmit from "./components/Cert-Contact/MessageSubmit";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";


function App() {

  useEffect(() => {
    Aos.init({duration: 700});
  }, [])

  return (
    <>
    <Router> 
      
      <Routes>
        <Route exact path="/"
          element={
            <>
            <Navbar /> 
            <About />
            <Skillset />
            <Projects />
            <CertContact />
            <Footer />
            </>
          }
        />         
        <Route exact path="/submit_message" element={<MessageSubmit />} />
      </Routes>

    </Router>
    </>
  );
}

export default App;