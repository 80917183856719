import React from "react";
import {Card} from "react-bootstrap";
import {motion} from "framer-motion";
import {FiExternalLink} from "react-icons/fi"
import "./CardProject.css";

function CardProject(props){

  return(
    <>
      <Card className="card-main" onMouseEnter={props.checkEnter} onMouseLeave={props.checkExit}>
        <Card.Img className="card-img" variant="top" src={props.image}/>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>
            {props.text}
            </Card.Text>
            <div className="card-links">
              {props.siteLink &&
                <motion.a whileHover={{ color:"#e4e4e4", backgroundColor:"#368ed6" }}
                  whileTap={{ scale: 0.9 }}
                  className="link-icon" href={props.siteLink} rel="noopener noreferrer"
                  target="_blank"><FiExternalLink size={20}/> 
                  {" "}Website
                </motion.a>
              }

              {props.githubLink &&
              <motion.a whileHover={{ color:"#e4e4e4", backgroundColor:"#368ed6" }}
                whileTap={{ scale: 0.9 }}
                className="link-icon" href={props.githubLink} rel="noopener noreferrer"
                target="_blank"><FiExternalLink size={20}/>
                {" "}Github
                </motion.a>
              }
            </div>
          
        </Card.Body>
      </Card>
    </>
  )
}

export default CardProject;