import React from "react";
import {Container} from "react-bootstrap";
import Certificate from "./Certificates";
import Contact from "./Contact";
import "./Cert-Contact.css";

function Certificates(){
  
  return(
    <>
    <Container fluid className="cert-contact-container">

      <Container fluid className="cert-contact-section" id="certificates">
        
        <div className="certificates-heading">
          <h1 className="paint-stroke">Certificates</h1>
        </div>

        <Container className="certificates">

          <div className="certificates-div">
            <div className="cert-caro">
                <Certificate />
            </div>
          </div>

        </Container>

        <Container className="contact" id="contact">
        <div className="contact-heading">
              <h1 className="paint-stroke contact-stroke">Contact</h1>
            </div>

          <div className="contact-div">  
              <div className="form">
                <Contact /> 
            </div>
          </div>

        </Container>
       
      </Container>

    </Container>
    </>
  );
}

export default Certificates;