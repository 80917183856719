import React from "react";
import {Col, Row} from "react-bootstrap";
import {AiOutlineConsoleSql} from "react-icons/ai";
import {FaNodeJs} from "react-icons/fa";
import {DiReact, DiMongodb, DiPython, DiCss3} from "react-icons/di";
import {SiJavascript, SiJava, SiHtml5, SiMariadb} from "react-icons/si";
import "./Skillstack.css";

function Skillstack() {
  return (
    <Row className="skillstack-center">
      <Col xs={4} md={2} className="skill-tech-icons">
        <SiJavascript /><strong className="skill-icon-name"><br/>Javascript</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <SiJava /><strong className="skill-icon-name"><br/>Java</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <DiPython /><strong className="skill-icon-name"><br/>Python</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <FaNodeJs /><strong className="skill-icon-name"><br/>NodeJS</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <DiReact /><strong className="skill-icon-name"><br/>React</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <SiHtml5 /><strong className="skill-icon-name"><br/>HTML5</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <DiCss3 /><strong className="skill-icon-name"><br/>CSS3</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <AiOutlineConsoleSql /><strong className="skill-icon-name"><br/>SQL</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <DiMongodb /><strong className="skill-icon-name"><br/>MongoDB</strong>
      </Col>
      <Col xs={4} md={2} className="skill-tech-icons">
        <SiMariadb /><strong className="skill-icon-name"><br/>MariaDB</strong>
      </Col>
    </Row>
  );
}

export default Skillstack;