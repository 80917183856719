import React from "react";
import {Carousel} from "react-bootstrap";
import {FiExternalLink} from "react-icons/fi";
import "./Certificates.css";

function Certificates(){

  return(
    <>
      <Carousel fade variant="dark" indicators={false}> 
        <Carousel.Item>

          <img
            className="d-block w-100"
            src="/images/ZTM-Python-Cert.jpg"
            alt="python cert"
          />
          <Carousel.Caption>
            <h3 className="caro-caption">Zero To Mastery Python 2021</h3>
            <p className="caro-p">
              Udemy{" "}   
              <a className="caro-link" href="https://www.udemy.com/certificate/UC-48cec7e6-6d24-42ce-a8d3-57921c687bab/" target="_blank" rel="noopener noreferrer">
                <FiExternalLink className="caro-link-icon" size={26}/>
              </a>
            </p>
            <div className="skill-div">
              <p>
                <span className="skill">Python</span>{" "}
                <span className="skill">Web Development</span>{" "}            
                <span className="skill">Web Scraping</span>{" "}
                <span className="skill">Scripting</span>{" "}
                <span className="skill">Automation/Testing</span>
              </p>
            </div>      

            
            {/* <a className="caro-link" href="">linksss</a> */}
          </Carousel.Caption>
          
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/Complete-Web-Development-Bootcamp.jpg"
            alt="web dev cert"
          />
          <Carousel.Caption>
            <h3 className="caro-caption">Complete Web Development <br/> Bootcamp 2022</h3>
            <p className="caro-p">
              Udemy{" "}   
                <a className="caro-link" href="https://www.udemy.com/certificate/UC-3bd30690-461f-4676-a6fd-5aa2d241ad65/" target="_blank" rel="noopener noreferrer">
                  <FiExternalLink className="caro-link-icon" size={26}/>
                </a>
              </p>
            <div className="skill-div">
            <p>
              <span className="skill">Node.js</span>{" "}
              <span className="skill">Express.js</span>{" "}     
              <span className="skill">React.js</span>{" "}       
              <span className="skill">MongoDB</span>{" "}
              <span className="skill">REST API</span>{" "}
              <span className="skill">JQuery</span>{" "}
              <span className="skill">EJS</span>
            </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/ShopeeCodeLeague.jpg"
            alt="shopee code leage cert"
          />

          <Carousel.Caption>
            <h3 className="caro-caption">Shopee Code League 2020</h3>
            <p className="caro-p">Shopee</p> 
            <div className="skill-div">
            <p>
              <span className="skill">Data Science 101</span>{" "}
              <span className="skill">Dynamic Programming</span>     
            </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/NICF-Cert.jpg"
            alt="NICF cert"
          />

          <Carousel.Caption>
            <h3 className="caro-caption">
              Advanced Certificate in Network Operations Management, 
              Monitoring and Maintenance Cert 2019
            </h3>
            <p className="caro-p">NCIF</p>
            <div className="skill-div">
            <p>
                <span className="skill">Network Design and Management</span>
            </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/uom-html5.JPG"
            alt="html5 cert"
          />

          <Carousel.Caption>
            <h3 className="caro-caption">
              Introduction to HTML5
            </h3>
            <p className="caro-p">NCIF</p>
            <div className="skill-div">
            <p>
                <span className="skill">HTML5</span>
            </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>
    </>
  )
}

export default Certificates;