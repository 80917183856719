import React from "react";
import Particles from "react-tsparticles";

function Particle() {


  return ( <
      Particles id = "tsparticles"
      options = {
          {
            fpsLimit: 60,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "grab",
                    },
                    resize: true,
                },
                modes: {
                    bubble: {
                        distance: 100,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
              },
              particles: {
                  color: {
                      value: "#ffffff",
                  },
                  bubble: {
                      color: "#ffffff",
                      distance: 150,
                      enable: true,
                      opacity: 0.5,
                      width: 1,
                  },
                  collisions: {
                      enable: true,
                  },
                  move: {
                      direction: "none",
                      enable: true,
                      outMode: "bounce",
                      random: false,
                      speed: 0.5,
                      straight: false,
                  },
                  number: {
                      density: {
                          enable: true,
                          area: 700,
                      },
                      value: 80,
                  },
                  opacity: {
                      value: 0.5,
                  },
                  shape: {
                      type: "circle",
                  },
                  size: {
                      random: true,
                      value: 5,
                  },
              },
              detectRetina: true,
          }
      }
      />
  );
};

export default Particle;