import React from "react";
import {Col, Row} from "react-bootstrap";
import {AiFillGithub} from "react-icons/ai";
import {FaFigma} from "react-icons/fa"
import {DiGit} from "react-icons/di";
import {SiPostman, SiVisualstudiocode, SiHeroku, SiIntellijidea} from "react-icons/si";
import "./Toolsstack.css"

function Toolsstack() {
  return (
    <Row className="toolsstack-center">
      <Col xs={4} md={2} className="tool-tech-icons">
        <AiFillGithub /><strong className="tool-icon-name"><br/>GitHub</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
        <DiGit /><strong className="tool-icon-name"><br/>Git</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
        <FaFigma /><strong className="tool-icon-name"><br/>Figma</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
        <SiHeroku /><strong className="tool-icon-name"><br/>Heroku</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
        <SiPostman /><strong className="tool-icon-name"><br/>Postman</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
       <SiVisualstudiocode /><strong className="tool-icon-name"><br/>VS Code</strong>
      </Col>
      <Col xs={4} md={2} className="tool-tech-icons">
        <SiIntellijidea /><strong className="tool-icon-name"><br/>Intellij</strong>
      </Col>
    </Row>
  );
}

export default Toolsstack;