import React, {useState} from "react";
import {Container} from "react-bootstrap";
import Marquee from "react-fast-marquee";
import CardProject from "./CardProject";
import "./Projects.css";


function Projects() {

  const [enter, setEnter] = useState(true);

  const checkEnter = () => setEnter(false);
  const checkExit = () => setEnter(true);
  return(
    <>
      <Container fluid className="project-section" id="projects">

        <div className="project-div">
          <h1 className="project-title">Projects</h1>
        </div>

          <div className="marquee-wrapper">
            <Marquee className="marquee" gradientColor="#003459"  speed="150" direction="right" pauseOnHover={!enter && "true"}>
            
              <CardProject
                image="/images/Website-Portfolio_v.1.png"
                title="Website Portfolio"
                text={
                      <span>
                        First version of personal Website Portfolio<br /><br/>
                        Built with
                        <span className="text-color"> HTML5</span> and 
                        <span className="text-color"> CSS3</span>
                      </span>
                    }
                siteLink="https://ronald-portfolio.web.app/"
                checkEnter={checkEnter}
                checkExit={checkExit}
              />
              
              <CardProject
                image="/images/ang-bao-signup.JPG"
                title="AngBao sign-up form"
                text={
                  <span>
                    Developed a sign-up page for users to register their details<br /><br/>
                    Built with 
                    <span className="text-color"> HTML5</span>,
                    <span className="text-color"> CSS3</span>,
                    <span className="text-color"> Javascript</span>,                  
                    <span className="text-color"> Node.js</span>,                  
                    <span className="text-color"> Express.js</span> with
                    <span className="text-color"> MailChimp API</span>
                  </span>
                  }
                linkText="Link to Ang Bao sign-up"
                siteLink="https://immense-beyond-95613.herokuapp.com/"
                githubLink="https://github.com/KohRonald/Angbao-Signup"
                checkEnter={checkEnter}
                checkExit={checkExit}
              />
              <CardProject
                image="/images/blog-website.JPG"
                title="Dynamic blog website"
                text={
                  <span>Developed a dynamic blog page connecting with 
                    <span className="text-color"> MongoDB</span><br /><br/>
                    Built with 
                    <span className="text-color"> Javascript</span>,                  
                    <span className="text-color"> Node.js</span>,                  
                    <span className="text-color"> Express.js</span>,
                    <span className="text-color"> EJS</span> and
                    <span className="text-color"> Mongoose</span>
                  </span>
                  }
                siteLink="https://still-dusk-30062.herokuapp.com/"
                githubLink="https://github.com/KohRonald/BlogWebsite-v.2"
                checkEnter={checkEnter}
                checkExit={checkExit}
              />
              <CardProject
                image="/images/to-do-list.JPG"
                title="Dynamic to-do-list tracker"
                text={
                  <span>
                    Developed a dynamic to-do-list page connecting with 
                    <span className="text-color"> MongoDB</span><br /><br/>
                    Built with 
                    <span className="text-color"> Javascript</span>,                  
                    <span className="text-color"> Node.js</span>,                  
                    <span className="text-color"> Express.js</span> and
                    <span className="text-color"> Mongoose</span>
                  </span>
                  }
                  linkText="Link to to-do-list website"
                  siteLink="https://polar-scrubland-71396.herokuapp.com/"
                  githubLink="https://github.com/KohRonald/To-do-list-v.2"
                checkEnter={checkEnter}
                checkExit={checkExit}
              />
            </Marquee>
          </div>

      </Container>  
    </>
  )
  

}

export default Projects;