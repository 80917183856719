import React, {useState} from "react";
import {Container} from "react-bootstrap";
import Axios from "axios";
import {motion} from "framer-motion";
import {AiOutlineMail} from "react-icons/ai";
import {IoPersonOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import "./Contact.css";

function Contact(){

  const [redirect, setRedirect] = useState(false);
  const [name, setName] = useState("")
  const navigate = useNavigate();

  const redirectToPage = () => setRedirect(true) ;  

  const customURL ="https://ronald-portfolio-17ec2.web.app";
  const url = customURL + "/message";
  
  const [data, setData] = useState({
    name: "",
    email:"",
    message: "",
    dateTime:""
  });

  function handle(e){
    const newdata={...data}
    newdata[e.target.id] = e.target.value;
    setData(newdata)
  };

 async function submit(e){

  let dateTime = require('node-datetime');
  let dt = dateTime.create();
  let currentDateTime = dt.format('Y-m-d H:M:S');
    
  e.preventDefault();
  setName(data.name);
  await Axios.post(url, {
    name: data.name,
    email: data.email,
    message: data.message,
    dateTime: currentDateTime
  })
  .then(res => res.status === parseInt("200") && (redirectToPage()))
  .catch(err => {
    console.log(err.response.status);
    console.log(err.response.headers);
    console.log("Error here in line 51")
  });
}

  return(
      <>
        {redirect && (
          navigate("/submit_message", {state: {name: name}})  
        )}    
        <Container className="main-contact-form" id="contact-form">

          <motion.h1 
            animate={{y: 10}}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: "reverse"
            }}className="contact-form-heading"> 
            Get in touch with me! <br />
            <span className="contact-span">You can say Hello 😄</span>
          </motion.h1>
    
          <form className="contactme-form" onSubmit={(e)=>submit(e)}>                    
              
            <div className="form-div">
              <span className="label-icon"><IoPersonOutline size={20} /></span> 

              <input className="input-box" 
                onChange={(e)=>handle(e)} value={data.name} 
                type="text" id="name"
                placeholder="Name" required 
              />
            </div> 
            
            <div className="form-div">
              <span className="label-icon"><AiOutlineMail size={20} /></span> 

              <input className="input-box" 
                onChange={(e)=>handle(e)} value={data.email} 
                type="email" id="email"
                placeholder="E-Mail" required 
              />
            </div>
            <br />  
            
            <textarea className="message-box" 
              onChange={(e)=>handle(e)} value={data.message} 
              type="text" id="message" 
              placeholder="Message" required
            />
            <br />

            <motion.input 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }} 
              className="submit-box" 
              type="submit" id="submitDetails"
            />
            <br />
                      
          </form>
        </Container>
      </>
    )
}

export default Contact;