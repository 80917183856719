import React, {useState, useEffect} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import TypeWriter from "./Typewriter";
import Particle from "./Particles";
import "aos/dist/aos.css";
import "./About.css";
import {AiOutlineGithub, AiOutlineMail, AiOutlineLinkedin} from "react-icons/ai";

function useWindowSize(){
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  useEffect(() => {
    const handleResize= () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])
  return size;
}


function About() {

  const [height, width] = useWindowSize();

  return(
    <>  
      <Container fluid className="about-section" id="about">
      <Particle />
      
        <Container className="about-content" fluid>
            <Col md={7} className="about-header">
              <h1 className="heading">
                  Hello! <span className="waving-hand">👋🏻</span>
              </h1>

              <h1 className="heading-name">
                  I AM <strong className="main-name">Ronald Koh</strong>
              </h1>

              <div className="typewriter">
                <TypeWriter /> 
              </div>               

              <div className="heading-social">
                <Button className="heading-links" size="lg" href="https://www.linkedin.com/in/ronald-koh-615920132/" >
                  <AiOutlineLinkedin size={30}/> {width > 425? "LinkedIn" : null}
                </Button>

                <Button className="heading-links" size="lg" href="https://github.com/KohRonald" variant="primary">
                  <AiOutlineGithub size={30}/> {width > 425? "Github" : null}
                </Button>
                
                <Button className="heading-links" size="lg" href="mailto:ronaldkohzx@gmail.com" variant="primary">
                  <AiOutlineMail size={30}/> {width > 425? "Email" : null}
                </Button>
              </div>
            </Col>
        </Container>
                     
        <Container className="about-me">
          
          <div data-aos="fade-up">
            <Row>
              <h1 className="about-h1">
                <span className="about-text">Who </span>am I?
              </h1>
              <Col md={8} className="about-description">
                  <p>
                    Final Year Computing and Information Systems student @SIM
                  </p>
                  <br />
                  <p>
                    Working on learning and implementing full stack development through various tech stacks
                  </p>
                  <br />
                  <p>
                    Highly motivated individual with a <span className="about-text">curious mindset </span>
                    to discover and explore new concepts to arm my toolbelt with as many web technologies
                    as I can
                  </p>
                  <br />
                  <p>
                    My field of interest are in breathing life into new ideas and products with the aid of
                    {" "}<b className="about-text"> Web Technologies </b>
                  </p>
                  <p>
                    I highly enjoy applying my passion for developing Web Apps with
                    <b className="about-text"> Node.js </b> and
                    {" "}
                    <i>
                      <b className="about-text">
                        Modern Javascript Library and Frameworks
                      </b>
                    </i>
                    {" "}such as{" "}
                    <i>
                      <b className="about-text">
                        React.js and EJS
                      </b>
                    </i>
                  </p>
              </Col>

              <Col md={4}>
                  <img className="profile-img" src="/images/HunnyAndMe.jpg" alt="My dog and I" />
              </Col>
           </Row>    
          </div>        
        </Container>

        {/* place this here so navbar won't block education heading */}
        <span id="education"/>
      </Container>
      
    </>
  )
}

export default About;