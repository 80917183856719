import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {useLocation} from "react-router-dom"
import {motion} from "framer-motion";
import Particle from "../About/Particles";
import {useNavigate} from "react-router-dom"
import "./MessageSubmit.css"

function MessageSubmit() {

  const [redirect, setRedirect] = useState(false);
  const {state} = useLocation();
  const {name} = state; 
  
  const navigate = useNavigate();
  const redirectToPage = () => setRedirect(true) ;  
  
  return (
    <>
      {redirect && navigate("/")}  
      <Particle />
      <Container fluid className="message-container">
    
        <div className="inner-message-container">
          <div className="name-message">
            <h2 className="name-inner"><span className="name">{name},</span></h2>
          </div>
          
          <p className="message-paragraph">Thank you for your message</p>
          <p className="message-paragraph">I will get back to you as soon as possible!</p>
          
          <div className="button-div">
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }} 
                className="back-button"
                type="button"  target="_blank"
                style={{fontWeight:"bold", color:"#a3841c"}}
                onClick={redirectToPage}
              >Back to Ronald's site</motion.button>
            </div>
        </div>
      </Container>
      
    </>
  )
}

export default MessageSubmit;