import React from "react";
import {Container} from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import "aos/dist/aos.css";
import "./Skillset.css";
import Skillstack from "./Skillstack";
import Toolsstack from "./Toolsstack";

function Skillset() {

  return(
    <>
      <Container fluid className="skillset">
      
        <Container fluid className="skillset-inner">

          <div className="edu-exp-card-heading" data-aos="zoom-in" >      
              <h1 className="paint-stroke">Education</h1>
          </div>
          
          <Container className="edu-exp-card-container">
          <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>   
            <div className="edu-exp-card"  data-aos="fade-up-left">

              <h2 className="exp-edu-title">SIM Global Education - University of London</h2>
              <p className="exp-edu-duration">Sep 2020 - present (Expected Sep 2023)</p>
              <h5 className="exp-edu-name">BSc in Computing and Information Systems</h5>
              <br />
              <p className="exp-edu-description">
                Gained insights on different types of information systems
                and the objective of such systems which manages crucial 
                information to aid businesses on the daily.
              </p>
              <p className="exp-edu-description" style={{color: "#f0b90b"}}>
                Programming modules
              </p>
              <ul className="exp-edu-list">
                <li>
                  Basic and advanced Java Programming concepts 
                </li>
                <li>
                   Database: SQL and MariaDB 
                </li>
                <li>
                  Software Engineering: Algorithm Design and Analysis
                </li>
              </ul>
              
            </div>
            </Tilt>
          </Container>
          
        
          <Container className="edu-exp-card-container">
          <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>   
            <div className="edu-exp-card" data-aos="fade-up-right">

              <h2 className="exp-edu-title">Singapore Polytechnic (SP)</h2>
              <p className="exp-edu-duration">May 2015 - Mar 2018</p>
              <h5 className="exp-edu-name">Diploma in Electrical & Electronic Engineering</h5>
              <p className="exp-edu-description">Specialised in Power and Biomedical</p>
              <p className="exp-edu-description">
                Gained both insightful theory and hands-on experience on the basic and advance concepts 
                of Electrical, Electronic, Biomedical and Power.
              </p>
              <p className="exp-edu-description">
                Obtain new knowledge and fresh concepts through the technical and hands-on classes.
              </p>
              <p className="exp-edu-description" style={{color: "#f0b90b"}}>
                Co-Curricular Activites
              </p>
              <ul className="exp-edu-list">
                <li>
                  Singapore Polytechnic Dragonboat Team
                </li>
              </ul>

            </div>
            </Tilt>
          </Container>

        </Container>
          
        <Container fluid className="skillset-inner">

          <div className="edu-exp-card-heading" id="experience" data-aos="zoom-in">
            <h1 className="paint-stroke">Experience</h1>
          </div>

          <Container className="edu-exp-card-container">
          <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>  
            <div className="edu-exp-card" data-aos="fade-up-left">
              <h2 className="exp-edu-title">Singapore Armed Forces</h2>
              <p className="exp-edu-duration">Jul 2018 - May 2020</p>
              <h5 className="exp-edu-name">Detachment Commander</h5>                
              <br />
              <p className="exp-edu-description">
                Posted to the 10C4I (Command, Control, Communications, Computers, Intelligence) battalion 'A' Coy.
                Mission to provide faster response to support the High Readiness Core (HRC) in security operations.
              </p>
              <p className="exp-edu-description">
                Served as a Detachment Commander in charge of both C4 and SATCOM systems.
                Mastering both systems to set-up and troubleshoot to ensure operational readiness.
              </p>
              <p className="exp-edu-description">
                Oversee the welfare of my subordinates. Ensure the upkeep and operativeness of logistics.
              </p>
              <p className="exp-edu-description" style={{color: "#f0b90b"}}>
                Responsibilities:
              </p>
              <ul className="exp-edu-list">
                <li>
                  System Adminstrator and Maintenance of Computers(C4) and SATCOM Systems
                </li>
                <li>
                  Coordinating people to ensure tasks are accomplished swiftly and efficiently
                </li>
              </ul>
            </div>
            </Tilt>
          </Container>
              
          <Container className="edu-exp-card-container">
          <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>  
            <div className="edu-exp-card" data-aos="fade-up-right">
              <h2 className="exp-edu-title">Palico Biotech Pte. Ltd.</h2>
              <p className="exp-edu-duration">Sep 2016 - Nov 2016</p>
              <h5 className="exp-edu-name">Sales Representative (Internship)</h5>
              <br />
              <p className="exp-edu-description">
                Assigned as a B2B sales representative where my task entailed was to generate sales leads as well as 
                to provide after service support for customers.
              </p>
              <p className="exp-edu-description" style={{color: "#f0b90b"}}>
                Responsibilities:
              </p>
              <ul className="exp-edu-list">
                <li>
                  Garner sales through Cold-calling, Prospecting Email and D2D                
                </li>
                <li>
                  Provide after-sales training                 
                </li>
              </ul>
            </div>
            </Tilt>
          </Container>
       
        </Container>    

        {/* place this here so navbar won't block skills heading */}
        <span id="skills"/>
        <br/>

        <Container fluid className="skills">

          <div className="skill-tech-stack-inner">
            <div className="skill-tech-stack-heading" data-aos="zoom-in">
              <h1 className="paint-stroke">Skills</h1>
            </div>
            <div data-aos="flip-left">
              <Skillstack />
            </div>
          </div>

          <div className="skill-tech-stack-inner">
            <div className="skill-tech-stack-heading tech-heading" id="tools" data-aos="zoom-in">
              <h1 className="paint-stroke tools-stroke">Tools</h1>
            </div>
            <div data-aos="flip-right">
              <Toolsstack />
              </div>
          </div>     
       
        </Container>
      </Container>
    </>
  )
}

export default Skillset;